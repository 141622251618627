import {
  CopyOutlined,
  DeleteOutlined,
  DownOutlined,
  ExclamationCircleOutlined,
  ImportOutlined,
  PoweroffOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Button,
  Checkbox,
  Dropdown,
  Form,
  Menu,
  message,
  Popconfirm,
  Radio,
  Space,
  Tag,
  Tooltip,
  Typography,
} from 'antd';
import copyToClipboard from 'copy-to-clipboard';
import { isEqual } from 'lodash';
import React, { useCallback, useState } from 'react';
import { mutate } from 'swr';
import { AddRemoveEntityTable, CodeEditor } from '../../components';
import api from '../../services/api';
import {
  confirmAction,
  DeleteEntityPopconfirm,
  SaveEntityModal,
  SaveEntityModalControlledVisibility,
  SelectPopconfirm,
  ToggleEnablePopconfirm,
} from '../../services/handlers';
import { useAsync } from '../../services/hooks';
import { compareAlphabetically, generateErrorMessage, validateJson } from '../../services/utils';
import { ORG_SETTINGS, WEBSITE_SETTINGS } from './SettingConfigs';

// we need to flatten settings in order to properly compare two setting values to handle cases where
// values have been added and removed (when this happens, additional keys are added to the setting obj
// which remain even if the value is later updated to an empty state)

// example of input/output: { value: {}, _enabled: false } --> { _enabled: false}
function flattenSettings(settings) {
  const flattenedSettings = {};

  for (const setting in settings) {
    if (!settings.hasOwnProperty(setting)) {
      continue;
    }

    if (typeof settings[setting] === 'object') {
      const flatObject = flattenSettings(settings[setting]);
      for (const key in flatObject) {
        if (!flatObject.hasOwnProperty(key)) continue;

        // only add setting to flat map if it exists, or if it's a boolean type (eg. enabled: false needs to be added)
        if (typeof flatObject[key] === 'boolean' || flatObject[key]) {
          flattenedSettings[`${setting}.${key}`] = flatObject[key];
        }
      }
    } else {
      flattenedSettings[setting] = settings[setting];
    }
  }
  return flattenedSettings;
}

function prepareSettingsTableData(currentSettings, currentSettingsForOtherScope, SETTINGS_CONFIG) {
  const addedSettings = [];
  const addableSettings = [];
  Object.entries(SETTINGS_CONFIG)
    .sort(
      ([_keyA, settingA], [_keyB, settingB]) => compareAlphabetically(settingA.name, settingB.name) // Sort settings by name
    )
    .forEach(([settingKey, setting]) => {
      if (currentSettings[settingKey]) {
        addedSettings.push({
          key: settingKey,
          settingDifferentInOtherScope: !isEqual(
            flattenSettings(currentSettings[settingKey]),
            flattenSettings(currentSettingsForOtherScope[settingKey])
          ),
          ...setting,
          ...currentSettings[settingKey],
        });
      } else if (!setting.isDeprecated) {
        // Filter out deprecated settings so that they cannot be added (but are still shown in the table if previously set)
        addableSettings.push({
          key: settingKey,
          name: setting.name,
        });
      }
    });
  return {
    addedEntities: addedSettings,
    addableEntities: addableSettings,
  };
}

const ImportJsonSettingsForm = ({ formInstance, saveError, settingsScope }) => {
  const settingsScopeString = settingsScope === 'prod_params' ? 'Production' : 'Staging';
  return (
    <Space direction="vertical">
      <Alert
        message={`Settings which are imported will be merged with those currently in ${settingsScopeString} (existing settings will be overwritten, new settings will be appended).`}
        type="info"
      />
      <Alert
        message="Be VERY careful with the settings you attempt to import! Errors in the JSON settings data may cause unexpected behaviour!"
        type="warning"
      />
      <Form name="import-settings" form={formInstance} preserve={false} requiredMark={false}>
        <Form.Item
          name="settings"
          rules={[
            {
              required: true,
              validator: (rule, value) => validateJson(value),
              message: 'Please enter a valid Settings JSON object!',
            },
          ]}
        >
          <CodeEditor placeholder="Settings JSON to import" height={300} />
        </Form.Item>
        {saveError && <Alert message={saveError} type="error" showIcon />}
      </Form>
    </Space>
  );
};

const ImportSettings = ({
  orgOrWebsite,
  SETTINGS_CONFIG,
  settingsScope,
  currentSettings,
  updateSettings,
  onUpdateSuccess,
}) => {
  const [isImportJsonModalVisible, setImportJsonModalVisibility] = useState(false);

  const handleDropdownMenuClick = useCallback(
    ({ key }) => {
      if (key === 'json') {
        setImportJsonModalVisibility(true);
      } else if (key === 'from-staging-or-prod') {
        if (settingsScope === 'prod_params') {
          const currentStagingSettings = orgOrWebsite.stg_params.script_settings;
          confirmAction({
            action: () => updateSettings({ newProdSettings: currentStagingSettings }),
            prompt:
              'Are you sure you want to replace the Production settings with the ones currently set in Staging?',
            onSuccess: onUpdateSuccess,
          });
        } else if (settingsScope === 'stg_params') {
          const currentProdSettings = orgOrWebsite.prod_params.script_settings;
          confirmAction({
            action: () => updateSettings({ newStagingSettings: currentProdSettings }),
            prompt:
              'Are you sure you want to replace the Staging settings with the ones currently set in Production?',
            onSuccess: onUpdateSuccess,
          });
        }
      }
    },
    [orgOrWebsite, settingsScope, updateSettings, onUpdateSuccess]
  );

  const dropdownMenu = (
    <Menu
      onClick={handleDropdownMenuClick}
      items={[
        { key: 'json', label: 'Import Settings from JSON' },
        {
          key: 'from-staging-or-prod',
          label: `Import Settings from ${
            settingsScope === 'prod_params' ? 'Staging' : 'Production'
          }`,
        },
      ]}
    />
  );

  return (
    <>
      <Dropdown key="import-settings" overlay={dropdownMenu}>
        <Button icon={<ImportOutlined />}>
          Import <DownOutlined />
        </Button>
      </Dropdown>
      <SaveEntityModalControlledVisibility
        isVisible={isImportJsonModalVisible}
        setVisibility={setImportJsonModalVisibility}
        modalTitle={`Import Settings to ${
          settingsScope === 'prod_params' ? 'Production' : 'Staging'
        }`}
        saveEntity={(newSettings) =>
          updateSettings({
            [settingsScope === 'prod_params' ? 'newProdSettings' : 'newStagingSettings']: {
              ...currentSettings,
              ...newSettings,
            },
          })
        }
        onSuccess={onUpdateSuccess}
        formComponent={ImportJsonSettingsForm}
        formComponentProps={{ settingsScope }}
        transformBeforeSave={({ settings }) => {
          const parsedSettings = JSON.parse(settings);

          // Ensure settings being imported have keys in WEBSITE_SETTINGS if being imported to a website (& keys in ORG_SETTINGS if being imoprted to an org)
          return Object.entries(parsedSettings).reduce(
            (filteredSettings, [settingKey, settingData]) => {
              if (SETTINGS_CONFIG[settingKey]) {
                // SETTINGS_CONFIG will be equal to WEBSITE_SETTINGS for a website and ORG_SETTINGS for an org
                filteredSettings[settingKey] = settingData;
              }
              return filteredSettings;
            },
            {}
          );
        }}
      />
    </>
  );
};

const CopySelectedSettings = ({ selectedSettings, clearSelection }) => (
  <Button
    icon={<CopyOutlined />}
    onClick={() => {
      let settingsJson;
      try {
        settingsJson = JSON.stringify(selectedSettings, null, 2);
        copyToClipboard(settingsJson);
        clearSelection();
        message.success(
          `Setting${selectedSettings.length === 1 ? '' : 's'} successfully copied to clipboard!`
        );
      } catch (error) {
        message.error(`Copying to clipboard failed. ${generateErrorMessage(error)}`);
      }
    }}
  >
    Copy
  </Button>
);

const EnableSelectedSettings = ({
  selectedSettingKeys,
  numEnablableSettings,
  clearSelection,
  enableSettings,
  onUpdateSuccess,
}) => (
  <Popconfirm
    title={`Are you sure you want to enable ${
      numEnablableSettings === 1 ? 'this 1 setting' : `these ${numEnablableSettings} settings`
    }?`}
    okText="Confirm"
    cancelText="Cancel"
    onConfirm={() => {
      enableSettings(selectedSettingKeys)
        .then((updatedOrgOrWebsite) => {
          clearSelection();
          onUpdateSuccess(updatedOrgOrWebsite);
        })
        .catch((errorMessage) => {
          message.error(`Update failed. ${generateErrorMessage(errorMessage)}`);
        });
    }}
    placement="topRight"
  >
    {/* Only show button when there are selected settings that can be enabled */}
    {numEnablableSettings > 0 ? <Button icon={<PoweroffOutlined />}>Enable</Button> : null}
  </Popconfirm>
);

const RemoveSelectedSettings = ({
  selectedSettingKeys,
  clearSelection,
  removeSettings,
  onUpdateSuccess,
}) => (
  <DeleteEntityPopconfirm
    triggerRender={() => (
      <Button ghost danger icon={<DeleteOutlined />}>
        Remove
      </Button>
    )}
    prompt={`Are you sure you want to remove ${
      selectedSettingKeys.length === 1
        ? 'this 1 setting'
        : `these ${selectedSettingKeys.length} settings`
    }?`}
    deleteEntity={() => removeSettings(selectedSettingKeys)}
    onDeleted={(updatedOrgOrWebsite) => {
      clearSelection();
      onUpdateSuccess(updatedOrgOrWebsite);
    }}
  />
);

const selectedSettingsActions = ({
  selectedRowKeys: selectedSettingKeys,
  clearSelection,
  currentSettings,
  enableSettings,
  removeSettings,
  onUpdateSuccess,
}) => {
  const selectedSettings = selectedSettingKeys.reduce(
    (selectedSettings, settingKey) => ({
      ...selectedSettings,
      [settingKey]: currentSettings[settingKey],
    }),
    {}
  );
  const numEnablableSettings = Object.values(selectedSettings).filter(({ _enabled }) => !_enabled)
    .length;

  return [
    <CopySelectedSettings
      key="copy-selected"
      selectedSettings={selectedSettings}
      clearSelection={clearSelection}
    />,
    <EnableSelectedSettings
      key="enable-selected"
      selectedSettingKeys={selectedSettingKeys}
      numEnablableSettings={numEnablableSettings}
      clearSelection={clearSelection}
      enableSettings={enableSettings}
      onUpdateSuccess={onUpdateSuccess}
    />,
    <RemoveSelectedSettings
      key="remove-selected"
      selectedSettingKeys={selectedSettingKeys}
      clearSelection={clearSelection}
      removeSettings={removeSettings}
      onUpdateSuccess={onUpdateSuccess}
    />,
  ];
};

const SettingsTable = ({ org, website, isViewOnly }) => {
  const isWebsiteSettingsTable = !!website;

  const SETTINGS_CONFIG = isWebsiteSettingsTable ? WEBSITE_SETTINGS : ORG_SETTINGS;
  const orgOrWebsite = isWebsiteSettingsTable ? website : org; // Entity whose settings will be read/updated
  const [settingsScope, setSettingsScope] = useState('prod_params'); // Must only be set to either 'prod_params' or 'stg_params'

  // Fetches a website setting's corresponding org setting (for a website settings table)
  const getCorrespondingOrgSetting = isWebsiteSettingsTable
    ? (websiteSettingKey) => {
        const correspondingOrgSettings = org[settingsScope].script_settings;
        const correspondingOrgSettingKey = websiteSettingKey.replace('__website', '__org');
        return correspondingOrgSettings[correspondingOrgSettingKey];
      }
    : () => null; // return null for org settings table

  const updateOrgOrWebsite = isWebsiteSettingsTable ? api.updateWebsite : api.updateOrg;

  const { execute: updateSettings } = useAsync(
    ({
      newStagingSettings = orgOrWebsite.stg_params.script_settings,
      newProdSettings = orgOrWebsite.prod_params.script_settings,
    }) =>
      updateOrgOrWebsite({
        id: orgOrWebsite.id,
        stg_params: { script_settings: newStagingSettings },
        prod_params: { script_settings: newProdSettings },
      })
  );
  const updateSetting = (settingKey, { _enabled, _overrides_org, value }) => {
    const updatedSetting = {
      _enabled,
      value,
    };
    // Ensure `_overrides_org` is only ever saved in website settings, it should be `null` otherwise
    if (isWebsiteSettingsTable) {
      updatedSetting._overrides_org = _overrides_org;
    }

    return updateOrgOrWebsite({
      id: orgOrWebsite.id,
      [settingsScope]: {
        script_settings: {
          ...orgOrWebsite[settingsScope].script_settings,
          [settingKey]: updatedSetting,
        },
      },
    });
  };
  const removeSettings = (settingKeysToRemove) => {
    const updatedSettings = { ...orgOrWebsite[settingsScope].script_settings };
    settingKeysToRemove.forEach((keyToRemove) => {
      delete updatedSettings[keyToRemove];
    });

    return updateOrgOrWebsite({
      id: orgOrWebsite.id,
      [settingsScope]: {
        script_settings: updatedSettings,
      },
    });
  };
  const enableSettings = (settingKeysToEnable) => {
    const updatedSettings = { ...orgOrWebsite[settingsScope].script_settings };
    settingKeysToEnable.forEach((keyToEnable) => {
      updatedSettings[keyToEnable] = { ...updatedSettings[keyToEnable], _enabled: true };
    });

    return updateOrgOrWebsite({
      id: orgOrWebsite.id,
      [settingsScope]: {
        script_settings: updatedSettings,
      },
    });
  };

  const onUpdateSuccess = isWebsiteSettingsTable
    ? ({ website }) => {
        // Show success message and refresh website with API response to reflect update
        message.success(`${website.domain} settings successfully updated!`);
        mutate(['/WebsiteGet', website.id], website, false);
      }
    : ({ org }) => {
        // Show success message and refresh org with API response to reflect update
        message.success(`${org.name} settings successfully updated!`);
        mutate(['/OrgGet', org.id], org, false);
      };

  const currentSettings = orgOrWebsite?.[settingsScope]?.script_settings; // The settings for the org or website (prod or staging determined by current `settingsScope`)
  const otherSettingsScope = settingsScope === 'prod_params' ? 'stg_params' : 'prod_params';
  const currentSettingsForOtherScope = orgOrWebsite?.[otherSettingsScope]?.script_settings;

  return (
    <AddRemoveEntityTable
      title="Settings"
      afterTitle={
        <Space>
          <Radio.Group
            onChange={(event) => {
              setSettingsScope(event.target.value);
            }}
            size="small"
            value={settingsScope}
          >
            <Radio.Button value="prod_params">Prod</Radio.Button>
            <Radio.Button value="stg_params">Staging</Radio.Button>
          </Radio.Group>
          {!isEqual(
            flattenSettings(currentSettings),
            flattenSettings(currentSettingsForOtherScope)
          ) && (
            <Tooltip title="Settings between Prod and Staging are not the same.">
              <ExclamationCircleOutlined />
            </Tooltip>
          )}
        </Space>
      }
      actions={
        isViewOnly ? null : (
          <ImportSettings
            orgOrWebsite={orgOrWebsite}
            SETTINGS_CONFIG={SETTINGS_CONFIG}
            settingsScope={settingsScope}
            currentSettings={currentSettings}
            updateSettings={updateSettings}
            onUpdateSuccess={onUpdateSuccess}
          />
        )
      }
      expandable
      selectable={
        isViewOnly
          ? false
          : {
              entityTerm: 'Setting',
              actions: selectedSettingsActions,
              actionsProps: { currentSettings, enableSettings, removeSettings, onUpdateSuccess },
            }
      }
      {...prepareSettingsTableData(currentSettings, currentSettingsForOtherScope, SETTINGS_CONFIG)}
      addEntity={(newSettingKey) =>
        updateSetting(newSettingKey, SETTINGS_CONFIG[newSettingKey].initialSetting)
      } // Initialize newly selected setting
      removeEntity={
        (settingKey) => removeSettings([settingKey]) // Remove setting
      }
      onUpdated={onUpdateSuccess}
      addInputPlaceholder="Add Setting"
      rowKey="key"
      columns={[
        {
          title: 'Name',
          dataIndex: 'name',
          // defaultSortOrder: 'ascend',
          // sortDirections: ['ascend', 'descend', 'ascend'],
          // sorter: (a, b) => a.name.localeCompare(b.name),
          render: (name, setting) => {
            const correspondingOrgSetting = getCorrespondingOrgSetting(setting.key);
            const otherSettingsScopeLabel =
              otherSettingsScope === 'prod_params' ? 'Production' : 'Staging';

            const isSetToNegateOrgSetting = setting._overrides_org === 'negate';
            return (
              <SaveEntityModal
                triggerRender={({ openModal }) => (
                  <Space>
                    <Tooltip
                      title={
                        isSetToNegateOrgSetting
                          ? 'This is set to negate the org setting, it therefore cannot be configured.'
                          : 'Configure'
                      }
                    >
                      <Button
                        type="link"
                        size="small"
                        onClick={openModal}
                        disabled={isSetToNegateOrgSetting}
                      >
                        {setting.value === false ? `(Do Not) ` : ''}
                        {name}
                      </Button>
                    </Tooltip>
                    {setting.settingDifferentInOtherScope ? (
                      <Tooltip
                        title={`This setting has not been applied, or has a different value in ${otherSettingsScopeLabel}.`}
                      >
                        <ExclamationCircleOutlined />
                      </Tooltip>
                    ) : null}
                  </Space>
                )}
                modalTitle={setting.name}
                transformBeforeSave={({ value }) => ({
                  _enabled: setting._enabled,
                  _overrides_org: setting._overrides_org,
                  value: setting.transformBeforeSave ? setting.transformBeforeSave(value) : value,
                })}
                saveEntity={(updatedSetting) => updateSetting(setting.key, updatedSetting)}
                onSuccess={onUpdateSuccess}
                formComponent={setting.Form}
                transformBeforeInit={({ value }) => ({
                  value: setting.transformBeforeInit ? setting.transformBeforeInit(value) : value,
                })}
                formInitialValues={{ value: setting.value }}
                formComponentProps={{
                  setting,
                  correspondingOrgSetting,
                }}
                isViewOnly={isViewOnly}
              />
            );
          },
          width: 250,
        },
        {
          title: 'Description',
          dataIndex: 'description',
        },
        ...(isWebsiteSettingsTable
          ? [
              {
                title: 'Override Org Setting',
                dataIndex: '_overrides_org',
                render: (overridesOrg, { key, _enabled, value }) => {
                  const isUniqueSetting = SETTINGS_CONFIG[key].isUniqueSetting; // Unique settings set on the website will always override the corresponding org setting.
                  const correspondingOrgSetting = getCorrespondingOrgSetting(key);
                  const isEnabledOnOrg = correspondingOrgSetting?._enabled;

                  return (
                    <Space size={0} style={{ justifyContent: 'flex-end', width: 130 }}>
                      {isEnabledOnOrg ? (
                        // TODO: Show like a binoculars icon link (with a tooltip being like "View org setting") that opens the org setting value in a (read-only?) modal form
                        <Tooltip
                          title={`This setting is${
                            _enabled ? ' also' : ''
                          } enabled on the org level${
                            overridesOrg === 'negate'
                              ? `, but is being negated here on the website level. ${
                                  _enabled ? 'Therefore' : 'Once enabled here,'
                                } the org setting will not affect this website.`
                              : overridesOrg === true
                              ? `, but is being overridden here on the website level. ${
                                  _enabled ? 'Only' : 'Once enabled here, only'
                                } the website setting value will be applied.`
                              : `. ${
                                  _enabled ? 'Both' : 'Once enabled here on the website level, both'
                                } setting values will be applied.`
                          }
                        `}
                        >
                          <Tag color="blue">
                            <Typography.Text delete={overridesOrg} disabled={overridesOrg}>
                              {/* Crossed-out when website setting overwrites or negates the org setting. */}
                              Org
                            </Typography.Text>
                          </Tag>
                        </Tooltip>
                      ) : null}
                      <SelectPopconfirm
                        value={overridesOrg}
                        options={[
                          ...(!isUniqueSetting ? [{ label: 'No', value: false }] : []), // Unique settings must override (or negate) website settings
                          { label: 'Yes', value: true },
                          { label: 'Negate', value: 'negate' },
                        ]}
                        selectValue={(newOverridesOrg) =>
                          updateSetting(key, { _enabled, _overrides_org: newOverridesOrg, value })
                        }
                        onSelected={onUpdateSuccess}
                        isDisabled={isViewOnly}
                        selectProps={{
                          size: 'small',
                          bordered: false,
                          dropdownMatchSelectWidth: false,
                          style: { width: 85 },
                        }}
                      />
                    </Space>
                  );
                },
                width: 130,
                align: 'center',
              },
            ]
          : []),
        {
          title: 'Enabled',
          dataIndex: '_enabled',
          render: (isEnabled, { key, _overrides_org, value }) => (
            <ToggleEnablePopconfirm
              isDisabled={isViewOnly}
              prompt={`Are you sure you want to ${isEnabled ? 'disable' : 'enable'} this?`}
              isToggled={isEnabled}
              toggleEnable={(newIsEnabled) =>
                updateSetting(key, { _enabled: newIsEnabled, _overrides_org, value })
              }
              onToggled={onUpdateSuccess}
            />
          ),
          width: 82,
          align: 'center',
        },
      ]}
      tableLayout="fixed"
      isViewOnly={isViewOnly}
    />
  );
};

export default SettingsTable;

import { ApartmentOutlined, GlobalOutlined } from '@ant-design/icons';
import { pdf } from '@react-pdf/renderer';
import { Button, Tooltip } from 'antd';
import { saveAs } from 'file-saver';
import React from 'react';
import { StandardIntegrationGuideDoc, NetworkIntegrationGuideDoc } from './IntegrationGuideDoc';

const linkButtonProps = {
  type: 'link',
  size: 'small',
  style: {
    width: 14,
    height: 'auto',
    marginLeft: 4,
    marginBottom: 3,
    verticalAlign: 'middle',
  },
};

/**
 * Link component which when clicked downloads a PDF detailing our *standard* BT integration instructions
 * @param {{ id: string, name: string }} org
 * @returns {null|JSX.Element}
 */
export const StandardIntegrationGuidePDFLink = ({ org }) => {
  const onClick = async () => {
    const blob = await pdf(<StandardIntegrationGuideDoc orgId={org.id} />).toBlob();

    saveAs(blob, `Blockthrough Integration Guide - ${org.name}.pdf`);
  };

  return (
    <Tooltip title="Download Blockthrough Integration Guide">
      <Button {...linkButtonProps} onClick={onClick} icon={<GlobalOutlined />} />
    </Tooltip>
  );
};

/**
 * Link component which when clicked downloads a PDF detailing our *(large-)network-specific* BT integration instructions
 * @param {{ id: string, name: string }} org
 * @param {{ domain: string }[]} orgWebsites
 * @returns {null|JSX.Element}
 */
export const NetworkIntegrationGuidePDFLink = ({ org, orgWebsites = [] }) => {
  const onClick = async () => {
    const blob = await pdf(
      <NetworkIntegrationGuideDoc orgId={org.id} exampleWebsiteDomain={orgWebsites[0]?.domain} />
    ).toBlob();

    saveAs(blob, `Network Integration Guide - ${org.name}.pdf`);
  };

  return (
    <Tooltip title="Download Network Integration Guide" overlayStyle={{ maxWidth: 260 }}>
      <Button {...linkButtonProps} onClick={onClick} icon={<ApartmentOutlined />} />
    </Tooltip>
  );
};

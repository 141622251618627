import {
  CheckSquareOutlined,
  EditOutlined,
  ExclamationCircleOutlined,
  TagOutlined,
} from '@ant-design/icons';
import {
  Alert,
  Badge,
  Button,
  Descriptions,
  Form,
  Input,
  PageHeader,
  Space,
  Switch,
  Tabs,
  Tag,
  Tooltip,
  Typography,
  message,
} from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import useSWR, { mutate } from 'swr';
import {
  AuditLog,
  CodeDiffViewerModal,
  CodeEditorModalStatic,
  OnceLoaded,
  OrgSoftMessageWallSettings,
  SearchableEntityTable,
  SettingsTable,
} from '../../components';
import { PageContents } from '../../layout';
import api from '../../services/api';
import { TIME_ZONE } from '../../services/constants';
import { SaveEntityModal } from '../../services/handlers';
import { useSessionUser } from '../../services/hooks';
import {
  compareAlphabetically,
  compareChronologically,
  removeCommentsFromJSCode,
} from '../../services/utils';
import { AddWhitelistRulesModal } from '../Website/WhitelistRulesTable';
import { AddWebsitesModal } from './AddWebsites';
import {
  NetworkIntegrationGuidePDFLink,
  StandardIntegrationGuidePDFLink,
} from './BTIntegrationGuide';
import { EditWebsiteModal, EditWebsitesModal } from './EditWebsites';
import ExportAdUnitCSV from './ExportAdUnitCSV';
import GAMExclusions from './GAMExclusions';
import GAMNetworks from './GAMNetworks';
import ImportAdUnitCSV from './ImportAdUnitCSV';
import RevShares from './RevShares';

const { Text } = Typography;

// content_enabled_record returns array of a record for each day that the website was enabled or disabled
const getLastWebsiteEnabledTime = (record) => {
  let latestTime;
  for (let i = record.length - 1; i >= 0; i--) {
    if (record[i].value) {
      latestTime = record[i].timestamp;
      break;
    }
  }
  return latestTime;
};

const selectedWebsitesActions = ({ selectedRowKeys, clearSelection, orgWebsites, org }) => {
  const selectedWebsites = orgWebsites.filter((website) => selectedRowKeys.includes(website.id));

  const editAction =
    selectedWebsites.length === 1 ? (
      <EditWebsiteModal
        key="edit-website"
        buttonProps={{
          icon: <EditOutlined />,
          type: 'default',
        }}
        org={org}
        website={selectedWebsites[0]}
        clearSelection={clearSelection}
      />
    ) : (
      <EditWebsitesModal
        key="edit-websites"
        org={org}
        orgWebsites={orgWebsites}
        selectedWebsites={selectedWebsites}
        clearSelection={clearSelection}
      />
    );

  return [
    <ExportAdUnitCSV key="export-csv" org={org} selectedWebsites={selectedWebsites} />,
    <AddWhitelistRulesModal
      key="add-whitelist-rules"
      buttonText="Add Whitelist Rules"
      buttonProps={{ icon: <CheckSquareOutlined />, type: 'default' }}
      org={org}
      orgWebsites={orgWebsites}
      selectedWebsites={selectedWebsites}
      clearSelection={clearSelection}
    />,
    editAction,
  ];
};

export const OrganizationForm = ({ formInstance, initialValues, saveError, isAddOrg }) => (
  <Form
    name="organization"
    form={formInstance}
    preserve={false}
    requiredMark={false}
    labelCol={{ span: 11 }}
    wrapperCol={{ span: 15 }}
  >
    <Form.Item
      name="name"
      label="Name"
      rules={[
        {
          required: true,
          whitespace: true, // Providing only whitespace is considered invalid
          message: 'Please input an organization name!',
        },
        // When editing an existing org, show warning if the user attempts to change the name
        ...(isAddOrg
          ? []
          : [
              {
                validator: (rule, value) =>
                  value !== initialValues.name
                    ? Promise.reject('Editing the Organization name can break revenue reporting.')
                    : Promise.resolve(value),
                warningOnly: true,
              },
            ]),
      ]}
    >
      <Input />
    </Form.Item>
    {!isAddOrg && (
      <>
        <Form.Item name="enabled" label="Enabled" valuePropName="checked">
          <Switch />
        </Form.Item>
        <Form.Item
          name="enable_granular_website_config"
          label="Network (Use Website Config)"
          valuePropName="checked"
        >
          <Switch />
        </Form.Item>
      </>
    )}
    <Form.Item name="prebid_fallback" label="Enable Prebid Fallback" valuePropName="checked">
      <Switch />
    </Form.Item>
    {saveError && <Alert message={saveError.message} type="error" showIcon />}
  </Form>
);

const DEFAULT_TAB_KEY = 'websites';

const Organization = () => {
  // const navigate = useNavigate();
  const { orgId } = useParams();

  // NOTE: Only internal (i.e Admin & System Admin) users can view this page, and only System Admin users have edit permissions
  const { isSystemAdmin } = useSessionUser();
  const isViewOnly = !isSystemAdmin;

  const [activeTabKey, setActiveTabKey] = useState(DEFAULT_TAB_KEY);
  // Reset tab content if navigating from one org's Organization Details page to another org's
  useEffect(() => {
    setActiveTabKey(DEFAULT_TAB_KEY);
  }, [orgId]);

  const { data: org, error: orgError } = useSWR(['/OrgGet', orgId]);
  const { data: orgWebsites, error: orgWebsitesError } = useSWR(['/WebsiteList', orgId], () =>
    api.listWebsites({ org_id: orgId })
  );
  const { data: networks, error: networksError } = useSWR(['/GAMNetworkList', orgId], () =>
    api.listGAMNetworks({ org_id: orgId })
  );

  const hasWebsiteWithCustomPbjs = orgWebsites?.some(
    (website) => website?.prod_params?.prebidjs_version
  );

  return (
    <OnceLoaded
      error={orgError || orgWebsitesError}
      isLoading={!org}
      render={() => (
        <>
          <PageHeader
            key="header"
            className="site-page-header"
            ghost={false}
            title={org.name}
            subTitle={
              <>
                <Text
                  copyable={{
                    tooltips: ['Copy org ID', 'Copied'],
                  }}
                  type="secondary"
                >
                  {org.id}
                </Text>
                <Text
                  copyable={{
                    icon: <TagOutlined />,
                    tooltips: ['Copy client script tag', 'Copied'],
                    text: `<script type="text/javascript" async src="https://btloader.com/tag?o=${org.id}&upapi=true"></script>`,
                  }}
                />
                <StandardIntegrationGuidePDFLink org={org} />
                <NetworkIntegrationGuidePDFLink org={org} orgWebsites={orgWebsites} />
              </>
            }
            breadcrumb={{
              routes: [
                {
                  path: '/orgs',
                  breadcrumbName: 'Organizations',
                },
                {
                  path: 'ignore', // used for BreadcrumbItem 'key' prop (required)
                },
              ],
              itemRender: ({ path, breadcrumbName }) => <Link to={path}>{breadcrumbName}</Link>,
            }}
            // onBack={() => navigate('/orgs')}
            extra={
              isSystemAdmin
                ? [
                    <SaveEntityModal
                      key="edit-org"
                      buttonText="Edit Organization"
                      modalTitle="Edit Organization"
                      // Add org id to request and trim any leading/trailing whitespace from org name
                      transformBeforeSave={({ name, enabled, ...rest }) => ({
                        id: org.id,
                        name: name.trim(),
                        disabled: !enabled,
                        ...rest,
                      })}
                      saveEntity={api.updateOrg}
                      onSuccess={({ org }) => {
                        // Show success message and trigger org refresh to reflect update
                        message.success(`${org.name} successfully updated!`);
                        mutate(['/OrgGet', org.id]);
                      }}
                      formComponent={OrganizationForm}
                      formInitialValues={{ ...org, enabled: !org.disabled }}
                    />,
                  ]
                : null
            }
            footer={
              <Tabs activeKey={activeTabKey} onChange={setActiveTabKey}>
                <Tabs.TabPane tab="Websites" key="websites" />
                <Tabs.TabPane tab="Settings" key="settings" />
                <Tabs.TabPane tab="Readership Link" key="soft-message-wall" />
                <Tabs.TabPane tab="GAM Networks" key="gam-networks" />
                <Tabs.TabPane tab="GAM Exclusions" key="gam-exclusions" />
                <Tabs.TabPane tab="Audit Log" key="audit-log" />
              </Tabs>
            }
          >
            <Descriptions size="small" column={{ xs: 1, sm: 1, md: 1, lg: 2 }}>
              <Descriptions.Item label="Status">
                <Badge
                  status={!org.disabled ? 'success' : 'default'}
                  text={!org.disabled ? 'Enabled' : 'Disabled'}
                />
              </Descriptions.Item>
              <Descriptions.Item label="Network (Use Website Config)">
                <Badge
                  status={org.enable_granular_website_config ? 'success' : 'default'}
                  text={org.enable_granular_website_config ? 'Enabled' : 'Disabled'}
                />
              </Descriptions.Item>
              <Descriptions.Item label="GAM Networks">
                <Button type="link" size="small" onClick={() => setActiveTabKey('gam-networks')}>
                  {networks
                    ? `${networks.length} GAM Network${networks?.length === 1 ? '' : 's'}`
                    : '0 GAM Networks'}
                </Button>
              </Descriptions.Item>
              <Descriptions.Item label="Created">
                <Space>
                  <span>{moment(org.creation_date).format('MMM D, YYYY [at] LT')}</span>
                  <span>({TIME_ZONE})</span>
                </Space>
              </Descriptions.Item>
              <Descriptions.Item label="Hooks">
                <Space>
                  {org?.prod_params?.hook && (
                    <CodeEditorModalStatic
                      triggerRender={({ openModal }) => (
                        <Button type="link" size="small" onClick={openModal}>
                          Production
                        </Button>
                      )}
                      modalTitle={`Production Hook (${org.name})`}
                      value={org.prod_params.hook}
                      language="js"
                    />
                  )}
                  {org?.stg_params?.hook && (
                    <CodeEditorModalStatic
                      triggerRender={({ openModal }) => (
                        <Button type="link" size="small" onClick={openModal}>
                          Staging
                        </Button>
                      )}
                      modalTitle={`Staging Hook (${org.name})`}
                      value={org.stg_params.hook}
                      language="js"
                    />
                  )}
                  {org?.prod_params?.hook &&
                  org?.stg_params?.hook &&
                  removeCommentsFromJSCode(org.prod_params.hook) !==
                    removeCommentsFromJSCode(org.stg_params.hook) ? (
                    <CodeDiffViewerModal
                      modalTitle={`Production Hook vs. Staging Hook (${org.name})`}
                      triggerRender={({ openModal }) => (
                        <Tooltip
                          title="Production & Staging hooks are not the same."
                          overlayStyle={{ maxWidth: 350 }}
                        >
                          <Button type="link" size="small" onClick={openModal}>
                            <ExclamationCircleOutlined />
                          </Button>
                        </Tooltip>
                      )}
                      width={1400}
                      language="js"
                      leftTitle="Production"
                      rightTitle="Staging"
                      diffViewerHeight={600}
                      value={{
                        before: org.prod_params.hook,
                        after: org.stg_params.hook,
                      }}
                    />
                  ) : null}
                </Space>
                {!org?.prod_params?.hook && !org?.stg_params?.hook && 'None'}
              </Descriptions.Item>
              <Descriptions.Item label="BT Revenue Share">
                <RevShares orgId={org.id} isViewOnly={isViewOnly} />
              </Descriptions.Item>
              <Descriptions.Item label="Prebid Fallback">
                <Badge
                  status={org.prebid_fallback ? 'success' : 'default'}
                  text={org.prebid_fallback ? 'Enabled' : 'Disabled'}
                />
              </Descriptions.Item>
              {org?.prod_params.prebidjs_version ? (
                <Descriptions.Item label="Prebid.js Version (Prod)">
                  {org.prod_params.prebidjs_version}
                </Descriptions.Item>
              ) : null}
              {org?.stg_params.prebidjs_version ? (
                <Descriptions.Item label="Prebid.js Version (Staging)">
                  {org.stg_params.prebidjs_version}
                </Descriptions.Item>
              ) : null}
            </Descriptions>
          </PageHeader>
          <PageContents>
            {activeTabKey === 'websites' ? (
              <SearchableEntityTable
                title="Websites"
                swrKey={['/WebsiteList', org.id]}
                swrFetcher={() => api.listWebsites({ org_id: org.id })}
                textSearchFieldNames={['domain', 'id']}
                selectable={
                  isViewOnly
                    ? false
                    : {
                        entityTerm: 'Website',
                        actions: selectedWebsitesActions,
                        actionsProps: { orgWebsites, org },
                      }
                }
                actions={
                  isViewOnly
                    ? null
                    : [
                        <ImportAdUnitCSV key="import-csv" org={org} orgWebsites={orgWebsites} />,
                        // Include Export CSV & Whitelisting actions when org has websites
                        ...(orgWebsites?.length > 0
                          ? [
                              <ExportAdUnitCSV
                                key="export-csv"
                                org={org}
                                numOrgWebsites={orgWebsites.length}
                              />,
                              <AddWhitelistRulesModal
                                key="add-whitelist-rules"
                                buttonText="Add Whitelist Rules"
                                buttonProps={{ icon: <CheckSquareOutlined />, type: 'default' }}
                                org={org}
                                orgWebsites={orgWebsites}
                              />,
                            ]
                          : []),
                        <EditWebsitesModal
                          key="edit-websites"
                          org={org}
                          orgWebsites={orgWebsites}
                        />,
                        <AddWebsitesModal key="add-websites" org={org} />,
                      ]
                }
                columns={[
                  {
                    title: 'Domain',
                    dataIndex: 'domain',
                    defaultSortOrder: 'ascend',
                    sortDirections: ['ascend', 'descend', 'ascend'],
                    sorter: (a, b) => compareAlphabetically(a.domain, b.domain),
                    render: (domain, { id, widget }) => (
                      <Space size={16} style={{ width: '100%', justifyContent: 'space-between' }}>
                        <Link to={`/websites/${id}`}>{domain}</Link>
                        {widget ? <Tag>Widget</Tag> : null}
                      </Space>
                    ),
                    width: 260,
                  },
                  {
                    title: 'ID',
                    dataIndex: 'id',
                    width: 220,
                  },
                  {
                    title: `Prebid.js Version`,
                    dataIndex: ['prod_params', 'prebidjs_version'],
                    hidden: !hasWebsiteWithCustomPbjs,
                    width: 160,
                  },
                  {
                    title: `Created (${TIME_ZONE})`,
                    dataIndex: 'created_at',
                    sortDirections: ['descend', 'ascend', 'descend'],
                    sorter: (a, b) => compareChronologically(a.created_at, b.created_at),
                    render: (dateISOString) => {
                      const date = moment(dateISOString);
                      return (
                        <Space>
                          <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                          <span>{date.format('(HH:mm)')}</span>
                        </Space>
                      );
                    },
                    width: 240,
                  },
                  {
                    title: `Enabled (${TIME_ZONE})`,
                    dataIndex: 'enabled_at',
                    sortDirections: ['descend', 'ascend', 'descend'],
                    sorter: (a, b) =>
                      compareChronologically(
                        getLastWebsiteEnabledTime(a.content_enabled_record),
                        getLastWebsiteEnabledTime(b.content_enabled_record)
                      ),
                    render: (_, { content_enabled_record }) => {
                      const latestTime = getLastWebsiteEnabledTime(content_enabled_record);
                      const date = latestTime ? moment(latestTime) : undefined;
                      return date ? (
                        <Space>
                          <span style={{ whiteSpace: 'nowrap' }}>{date.format('YYYY-MM-DD')}</span>
                          <span>{date.format('(HH:mm)')}</span>
                        </Space>
                      ) : (
                        <Space>
                          <span>N/A</span>
                        </Space>
                      );
                    },
                    width: 240,
                  },
                  // TODO: Update this column once we have stage_params.(content_enabled|mobile_enabled|dfp_enabled)
                  {
                    title: 'Status',
                    key: 'enabled',
                    sortDirections: ['ascend', 'descend', 'ascend'],
                    sorter: (a, b, currentSortDirection) => {
                      const isAEnabled = a.prod_params.content_enabled;
                      const isBEnabled = b.prod_params.content_enabled;
                      if (isAEnabled === isBEnabled) {
                        // Always sort sites by domain in ascending order when their enabled statuses are the same
                        // (So when this column is in descending order we need to reverse the result of the localeCompare function.)
                        return (
                          (currentSortDirection === 'descend' ? -1 : 1) *
                          compareAlphabetically(a.domain, b.domain)
                        );
                      } else {
                        return isAEnabled && !isBEnabled ? -1 : 1; // Enabled sites shown first when sorted in ascending order
                      }
                    },
                    render: (
                      _,
                      { prod_params: { content_enabled, mobile_enabled, dfp_enabled } = {} }
                    ) => {
                      // TODO: Make this into its own component?
                      const isEnabled = !!content_enabled;
                      return (
                        <Space>
                          <Badge
                            status={isEnabled ? 'success' : 'default'}
                            text={isEnabled ? 'Enabled' : 'Disabled'}
                            style={{ minWidth: 88 }}
                          />
                          <div>
                            {mobile_enabled && (
                              <Tag color={isEnabled ? 'blue' : 'default'}>Mobile</Tag>
                            )}
                            {dfp_enabled && (
                              <Tag color={isEnabled ? 'warning' : 'default'}>GAM</Tag>
                            )}
                          </div>
                        </Space>
                      );
                    },
                    width: 260,
                  },
                  {
                    key: 'actions',
                    hidden: isViewOnly,
                    render: (_, website) => (
                      <EditWebsiteModal
                        key="edit-website"
                        buttonProps={{ type: 'link' }}
                        org={org}
                        website={website}
                      />
                    ),
                    align: 'right',
                    width: 75,
                  },
                ].filter((column) => !column.hidden)}
              />
            ) : activeTabKey === 'settings' ? (
              <SettingsTable org={org} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'soft-message-wall' ? (
              <OrgSoftMessageWallSettings org={org} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'gam-networks' ? (
              <GAMNetworks orgId={orgId} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'gam-exclusions' ? (
              <GAMExclusions orgId={orgId} isViewOnly={isViewOnly} />
            ) : activeTabKey === 'audit-log' ? (
              <AuditLog orgId={orgId} />
            ) : null}
          </PageContents>
        </>
      )}
    />
  );
};

export default Organization;

import { Alert, Checkbox, Form, Input } from 'antd';
import React from 'react';
import { CodeEditor } from '../../../components';
import { validateJson } from '../../../services/utils';

const snigelConfigPlaceholder = `{ 
  "nn_lb1": "genshin_gg-Genshin_GG_LB1",
  "nn_lb2": "genshin_gg-Genshin_GG_LB2",
  "nn_skinl": "genshin_gg-Genshin_GG_Skin_left1"
}`;
const newSnigelConfigPlaceholder = `{ 
  "nn_lb1": "Genshin_GG_LB1",
  "nn_lb2": "Genshin_GG_LB2",
  "nn_skinl": "Genshin_GG_Skin_left1"
}`;

const EnableSnigelForm = ({ formInstance, isViewOnly, saveError }) => {
  // Track `value.useRosTag` form value to alter displayed for fields when checked
  const useRosTag = Form.useWatch(['value', 'useRosTag'], formInstance);
  // TODO: once every Snigel integration is migrated this should be removed
  const useNewConfig = Form.useWatch(['value', 'useNewConfig'], formInstance);

  return (
    <Form
      name="settings-enable-snigel"
      form={formInstance}
      disabled={isViewOnly}
      preserve={false}
      requiredMark={false}
    >
      <Form.Item
        name={['value', 'sid']}
        label="Seller ID (sid)"
        tooltip="If left empty, the Organization ID will be used by default. Otherwise, this can be set to override that default value if the `seller_id` in `blockthrough.com/sellers.json` differs from the Organization ID."
        rules={[
          {
            whitespace: true,
            message: 'Please enter a valid Seller ID!',
          },
        ]}
      >
        <Input placeholder="5741666032943100" />
      </Form.Item>
      <Form.Item
        name={['value', 'useNewConfig']}
        label="Use new Snigel config"
        valuePropName="checked"
        tooltip="Select this to use new Snigel Config"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item noStyle>
        {useNewConfig ? (
          <>
            <Form.Item
              name={['value', 'site']}
              label="Site"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter a site name!',
                },
              ]}
            >
              <Input placeholder="e.g., wowhead.com" />
            </Form.Item>
            <Form.Item
              name={['value', 'useRosTag']}
              label="Use single placement"
              valuePropName="checked"
              tooltip="Select this to use one placement value for every ad unit (instead of a 'Snigel Config' JSON object)"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item noStyle>
              {useRosTag ? (
                <Form.Item
                  name={['value', 'placement']}
                  label="Placement"
                  tooltip="The placement value that will be used for every Space/ad unit"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter a placement',
                    },
                  ]}
                >
                  <Input placeholder="e.g., run_of_site" />
                </Form.Item>
              ) : (
                <Form.Item
                  name={['value', 'snigelConfig']}
                  label="Snigel Config"
                  tooltip="A valid JSON object which consists of the space names and their corresponding placement"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      validator: (rule, value) => validateJson(value),
                      message: 'Please enter a valid JSON Snigel Config object!',
                    },
                  ]}
                >
                  <CodeEditor placeholder={newSnigelConfigPlaceholder} height={360} />
                </Form.Item>
              )}
            </Form.Item>
          </>
        ) : (
          <>
            <Form.Item
              name={['value', 'websiteStoredRequestId']}
              label="Website Stored Request ID"
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: 'Please enter a Website Stored Request ID!',
                },
              ]}
            >
              <Input placeholder="e.g., wowhead_com" />
            </Form.Item>
            <Form.Item
              name={['value', 'useRosTag']}
              label="Use single ROS tag"
              valuePropName="checked"
              tooltip="Select this to use one stored impression value for every ad unit (instead of a 'Snigel Config' JSON object)"
            >
              <Checkbox />
            </Form.Item>
            <Form.Item noStyle>
              {useRosTag ? (
                <Form.Item
                  name={['value', 'rosTag']}
                  label="ROS Tag name"
                  tooltip="The stored impression value that will be used for every Space/ad unit"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter an ROS Tag name',
                    },
                  ]}
                >
                  <Input placeholder="e.g., fanbyte_com-run_of_site" />
                </Form.Item>
              ) : (
                <Form.Item
                  name={['value', 'snigelConfig']}
                  label="Snigel Config"
                  tooltip="A valid JSON object which consists of the space names and their corresponding stored impression"
                  labelCol={{ span: 24 }}
                  rules={[
                    {
                      required: true,
                      validator: (rule, value) => validateJson(value),
                      message: 'Please enter a valid JSON Snigel Config object!',
                    },
                  ]}
                >
                  <CodeEditor placeholder={snigelConfigPlaceholder} height={360} />
                </Form.Item>
              )}
            </Form.Item>
          </>
        )}
      </Form.Item>
      {saveError && <Alert message={saveError.message} type="error" showIcon />}
    </Form>
  );
};

const settingConfig = {
  name: 'Enable Snigel (BT Demand)',
  description:
    'Enables and configures BT Demand via the Snigel bidder (s2s adapter) in our auctions.',
  Form: EnableSnigelForm,
  // Transform snigel config to and from JS Object <-> JSON string because form must use string object but we want to store the value as an Object
  transformBeforeInit: ({ snigelConfig, ...rest }) => ({
    snigelConfig: snigelConfig && JSON.stringify(snigelConfig, null, 2),
    ...rest,
  }),
  transformBeforeSave: ({ snigelConfig, ...rest }) => ({
    snigelConfig: snigelConfig && JSON.parse(snigelConfig),
    ...rest,
  }),
  initialSettingValue: {},
};

export default settingConfig;
